import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SidePanelFilterKey, SidePanelPosition } from 'components/molecules/SidePanel/SidePanel.types'
import { ComparePanelDropdownOption } from 'components/organisms/ComparePanel/ComparePanel.types'
import { NegotiationSidePanelIds } from 'components/organisms/NegotiationSidebar/NegotiationSidebar.types'
import { ActivityChannelFilterType } from 'components/organisms/NegotiationSidePanels/ActivityChannel/components/ActivityFilter/ActivityFilter.types'
import { ElectionsState } from 'types/redesignStore'

export const initialState: ElectionsState = {
  selectedElection: '',
  selectedDocumentPreviewId: '',
  openedSidePanel: undefined,
  sidePanelPosition: 'left',
  documentPreviewOpen: true,
  sidePanelFilterKey: 'all',
  compareOptions: [{ label: 'Counterparty (Latest)', type: 'cp', value: 'cp', created: '' }],
  comparePanelOpen: false,
  resetFormValues: false,
  selectedDocumentId: '',
  activePreviewSelector: '#document-preview-wrapper',
  pushAnswers: false,
  activityChannelFilter: 'all',
  selectedElectionLastUpdated: 0,
}

const electionsSlice = createSlice({
  name: 'elections',
  initialState,
  reducers: {
    resetNegotiationState: () => initialState,
    setSelectedElection(state, { payload }: PayloadAction<string>) {
      state.selectedElection = payload
      state.selectedElectionLastUpdated = Date.now()
    },
    setSelectedDocumentPreviewId(state, { payload }: PayloadAction<string>) {
      state.selectedDocumentPreviewId = payload
    },
    setOpenSidePanel: (state, { payload }: PayloadAction<NegotiationSidePanelIds | undefined>) => {
      state.openedSidePanel = payload === state.openedSidePanel ? undefined : payload
    },
    setSidePanelPosition: (state, { payload }: PayloadAction<SidePanelPosition>) => {
      state.sidePanelPosition = payload
    },
    setSidePanelFilterKey(state, { payload }: PayloadAction<SidePanelFilterKey>) {
      state.sidePanelFilterKey = payload
    },
    setDocumentPreviewOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.documentPreviewOpen = payload
    },
    setCompareOptions(state, { payload }: PayloadAction<ComparePanelDropdownOption[]>) {
      state.compareOptions = payload as ElectionsState['compareOptions']
    },
    setComparePanelOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.comparePanelOpen = payload
    },
    setResetFormValues: (state, { payload }: PayloadAction<boolean>) => {
      state.resetFormValues = payload
    },
    setSelectedDocumentId(state, { payload }: PayloadAction<string>) {
      state.selectedDocumentId = payload
    },
    setActivePreviewSelector(state, { payload }: PayloadAction<ElectionsState['activePreviewSelector']>) {
      state.activePreviewSelector = payload
    },
    setPushAnswers: (state, { payload }: PayloadAction<boolean>) => {
      state.pushAnswers = payload
    },
    setActivityChannelFilter(state, { payload }: PayloadAction<ActivityChannelFilterType>) {
      state.activityChannelFilter = payload
    },
  },
})

export const {
  setSelectedElection,
  setSelectedDocumentPreviewId,
  setOpenSidePanel,
  setSidePanelPosition,
  setSidePanelFilterKey,
  setDocumentPreviewOpen,
  setCompareOptions,
  setComparePanelOpen,
  resetNegotiationState,
  setResetFormValues,
  setSelectedDocumentId,
  setActivePreviewSelector,
  setPushAnswers,
  setActivityChannelFilter,
} = electionsSlice.actions
export default electionsSlice.reducer
