import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AlertsState } from 'types/redesignStore'

export const initialState: AlertsState = {
  message: '',
  variant: 'success',
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    resetAlert: () => ({ ...initialState }),
    setAlert: (state, { payload }: PayloadAction<AlertsState>) => ({
      ...state,
      ...payload,
      lastUpdated: new Date().getTime(),
    }),
  },
})

// No-argument actions need to be re-exported because redux-toolkit is wrong
export function resetAlert() {
  return alertsSlice.actions.resetAlert(undefined)
}

export const { setAlert } = alertsSlice.actions
export default alertsSlice.reducer
