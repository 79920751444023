import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PresetsState } from 'types/redesignStore'

export const initialState: PresetsState = {
  selectedPresetId: '',
  selectedDocumentVersion: '',
  showTemplatePreview: false,
  templateOptionsPanelOpen: false,
  importedAnswers: {},
}

const presetsSlice = createSlice({
  name: 'presets',
  initialState,
  reducers: {
    resetPresetState: () => initialState,
    setSelectedPresetId(state, { payload }: PayloadAction<string>) {
      state.selectedPresetId = payload
    },
    setSelectedDocumentVersion(state, { payload }: PayloadAction<string>) {
      state.selectedDocumentVersion = payload
    },
    setShowTemplatePreview(state, { payload }: PayloadAction<boolean>) {
      state.showTemplatePreview = payload
    },
    setTemplateOptionsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.templateOptionsPanelOpen = payload
    },
    setImportedAnswers: (state, { payload }: PayloadAction<PresetsState['importedAnswers']>) => {
      state.importedAnswers = payload
    },
  },
})

export const {
  resetPresetState,
  setSelectedPresetId,
  setSelectedDocumentVersion,
  setShowTemplatePreview,
  setTemplateOptionsOpen,
  setImportedAnswers,
} = presetsSlice.actions
export default presetsSlice.reducer
